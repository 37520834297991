import * as React from "react"
import { useState } from "react"
import { useEffect } from "react"
import { Col, Row } from "react-bootstrap"
import LocationIcon from "./LocationIcon"
import SpeechBubble from "./SpeechBubble"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import styled from "styled-components"

const StyledGrid = styled(BackgroundImage)`
  /* .map-popups {
    transform: scale(0.75);
  } */
  /* transform: scale(1.25); */
  &::before,
  &::after {
    /* filter: opacity(0.2); */
  }
  /* height: 75vh; */
  height: 120%;
`

const randomBetween = (a, b) => {
  return Math.round(Math.random() * (b - a) + a)
}

const nRandomBetween = (n) => {
  const nums = []
  while (nums.length < n) {
    const random = randomBetween(0, 5)
    if (!nums.includes(random)) nums.push(random)
  }
  return nums
}

function Grid(props) {
  const [numbers, setNumbers] = useState([])

  const data = useStaticQuery(graphql`
    {
      file(name: { eq: "CountryR-large" }, relativeDirectory: { eq: "country_report" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  useEffect(() => {
    const interval = setInterval(() => {
      setNumbers(nRandomBetween(2))
    }, 2000)
    return () => clearInterval(interval)
  }, [])

  return (
    <StyledGrid Tag="section" fluid={data.file.childImageSharp.fluid}>
      <Row className="h-100 pt-5 ">
        <Col className="pt-5 mt-5">
          <div
            className={`${
              numbers.includes(0) ? "m-fadeIn " : "m-fadeOut"
            }  d-flex flex-column align-items-center pb-5 mr-5 mb-5 map-popups`}
          >
            <SpeechBubble text="$96.37" color="green" className="mx-auto" />
            <span className="text-white mt-3 small">NORTH AMERICA</span>
          </div>
          <div
            className={`${
              numbers.includes(1) ? "m-fadeIn" : "m-fadeOut"
            }  d-flex flex-column align-items-center ml-5 mt-5 pt-5 map-popups`}
          >
            <SpeechBubble text="$260.45" color="blue" />
            <span className="text-white mt-3 small">SOUTH AMERICA</span>
          </div>
        </Col>
        <Col className="pt-5 mt-5">
          <div
            className={`${
              numbers.includes(2) ? "m-fadeIn" : "m-fadeOut"
            } d-flex flex-column align-items-center mt-5 map-popups`}
          >
            <LocationIcon className="w-25" />
            <span className="text-white mt-3 small">UNITED KINGDOM</span>
            <span className="secondary-blue-2-text small">£126.45</span>
          </div>
          <div
            className={`${
              numbers.includes(3) ? "m-fadeIn" : "m-fadeOut"
            } d-flex flex-column align-items-center mt-5 ml-5 map-popups`}
          >
            <SpeechBubble text="€96.37" color="blue-2" />
            <span className="text-white mt-3 small">EUROPE</span>
          </div>
        </Col>
        <Col className="ml-4 mt-5">
          <div
            className={`${
              numbers.includes(4) ? "m-fadeIn" : "m-fadeOut"
            } d-flex flex-column align-items-center ml-5 mb-5 pb-5 map-popups`}
          >
            <SpeechBubble text="Rs.5960" color="blue-2" />
            <span className="text-white mt-3 small">ASIA</span>
          </div>
          <div
            className={`${
              numbers.includes(5) ? "m-fadeIn" : "m-fadeOut"
            } d-flex flex-column align-items-center mt-5 mr-4 pt-5 map-popups`}
          >
            <LocationIcon className="w-25" />
            <span className="text-white mt-3 small">AUSTRALIA</span>
            <span className="secondary-blue-2-text small">AU$ 816.37</span>
          </div>
        </Col>
      </Row>
    </StyledGrid>
  )
}

export default Grid
