import React from "react"
import { Container } from "react-bootstrap"
import { FiChevronDown } from "react-icons/fi"
import { colors } from "../../../utils/styles"
import GraphImage from "./GraphImage"

const TrulyGlobal = () => {
  return (
    <section className="text-center secondary-blue-3-background py-5 px-5 px-lg-0">
      <Container>
        <GraphImage/>
        <h2 className="text-picton-blue my-4 text-left text-lg-center">
          Truly Global Processing Locally
        </h2>
        <p className="text-white text-left text-lg-center">
          Doing business in new markets is always fraught with complications from fulfilment to logistics to legal
          obstacles etc. APEXX specialises in guiding enterprise merchants into new markets. The following reports will
          provide readers with an understanding of the state of the eCommerce and payments landscapes in various markets
          around the globe.
        </p>
        <p className="secondary-blue-2-text mt-5">
          Select a market below to explore data on it's local payment's landscape
        </p>
        <FiChevronDown size="30" color={colors.secondaryBlue2} className="mx-auto " />
      </Container>
    </section>
  )
}

export default TrulyGlobal
