import React from "react"
import { Jumbotron, Container, Button, Row, Col } from "react-bootstrap"
import Grid from "./Grid"
import { HeroWrapper } from "./HeroSection.styles"
import { navigateTo } from "../../../utils/hooks"
import ApButton from "../../Global/Common/ApButton/ApButton"

const HeroSection = () => {
  return (
    <HeroWrapper>
      <div className=" rounded-0 pt-lg-5">
        {/* <Container className="py-5"> */}
        <Row className=" pt-5">
          <Col className="px-5 content-wrapper py-lg-5 pr-lg-3 my-lg-5" lg={{offset:1,span:4}}>
            <div className="mb-lg-5 ">
              <h1 className="blue-aqua">Global Payments</h1>
              <h2 className="h1 text-white">
                Payments. <br />
                Everywhere.
              </h2>
              <p className="text-white">
                Enterprise businesses need a complete understanding of how their
                customers want to pay in their major markets in order to
                optimise their payments setup.
              </p>
              <ApButton
                variant={'greenTwo'}
                className="border-0 mb-5 my-lg-4"
                role="link"
                onClick={(e) => navigateTo(e, "contactUs")}
              >
                Learn More
              </ApButton>
            </div>
          </Col>
          <Col className="d-none d-lg-block" lg={7}>
            <Grid />
          </Col>
        </Row>
        {/* </Container> */}
      </div>
    </HeroWrapper>
  )
}

export default HeroSection
