import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import HeadingWithBg from "../../Global/Common/HeadingWithBg/HeadingWithBg"
import CheckList from "../../Global/Common/CheckList/CheckList"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const InternationalCoverage = () => {
  const data = useStaticQuery(graphql`
    {
      file(
        relativeDirectory: { eq: "country_report/map" }
        name: { eq: "Map" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <section>
      <Container className="py-5 px-5 px-lg-0">
        <HeadingWithBg text="International Coverage" />
        <Row>
          <Col xs={12} lg={6}>
            <h2 className="h1 my-5 primary-blue-text">Be exceptional without borders</h2>
            <p>
              We help your business cut payments costs and improve conversion
              rates in all your major markets. We offer all major and local
              payment methods, local processing, and expert local payments
              knowledge to help your business grow.
            </p>
            <ul className="pl-0">
              <CheckList className="primary-blue-text">150+ processing currencies</CheckList>
              <CheckList className="primary-blue-text">Local processing in over 50 countries</CheckList>
              <CheckList className="primary-blue-text">Over 20 settlement currencies</CheckList>
            </ul>
          </Col>
          <Col xs={12} lg={6}>
            <Img fluid={data.file.childImageSharp.fluid} alt="globe map interconnected network graphic"/>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default InternationalCoverage
