import * as React from "react"
import { SpeechBubbleWrapper } from "./SpeechBubble.styles"

function SpeechBubble({ text, color }) {
  return (
    <SpeechBubbleWrapper className="text-center text-white" color={color}>
      <small>{text}</small>
    </SpeechBubbleWrapper>
  )
}

export default SpeechBubble
