import styled from "styled-components"

export const HeroWrapper = styled.section`
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.201868) 0%,
      rgba(255, 255, 255, 0.5) 100%
    ),
    #144056;
  background-blend-mode: overlay, normal;
  .content-wrapper {
    /* height: 75vh;
    min-height: 70vh; */
  }
  .content-wrapper {
    transform: scale(1.1);
  }
  .jumbotron {
    .btn {
      @media (max-width: 992px) {
        width: 100%;
      }
    }
  }
`
/* Rectangle */
