import React from "react"
import { CheckListWrapper } from "./CheckList.styles"

const CheckList = ({ children, className }) => {
  return (
    <CheckListWrapper className={`${className} d-flex mb-3 poppins`}>
      {children}
    </CheckListWrapper>
  )
}

export default CheckList
