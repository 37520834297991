import styled from "styled-components"
import { colors } from "../../../../utils/styles"

export const CheckListWrapper = styled.li`
  list-style: none;

  &:before {
    content: "✓";
    font-weight: bold;
    font-size: large;
    color: ${colors.secondaryBlue2};
    padding-right: 15px;
  }
`
