import { Link, graphql, useStaticQuery } from "gatsby"

import { Container } from "react-bootstrap"
import { FlagsWrapper } from "./Flags.styles"
import Img from "gatsby-image"
import React from "react"
import ReactTooltip from "react-tooltip"

const Flags = () => {
  const data = useStaticQuery(graphql`
    {
      allFile(
        filter: { relativeDirectory: { eq: "country_report/country_flags" } },
      ) {
        edges {
          node {
            id
            name
            childImageSharp {
              fixed(width: 50, height: 50, cropFocus: CENTER) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
      allContentfulCountryReport(
        sort: {fields: countryName, order: ASC}
      ) {
          edges {
            node {
            countryName
              countryFlag
              slug
            }
          }
        }
    }
  `)
  var countyReports = data.allContentfulCountryReport.edges;

  const images = data.allFile.edges
  return (
    <FlagsWrapper className="secondary-blue-3-background">
      <Container className="py-5 px-3 px-lg-0 ">
        <div className="grid">
          {countyReports.map((countyReport, i) => {

            const fixed = images.find(
              (image) => image.node.name === countyReport.node.countryFlag[0]
            ).node.childImageSharp.fixed

            return (
            <Link
                className="flag-container"
                to={`/country-report/${countyReport.node.slug}/`}
                target="_blank"
            >
              <div className="d-flex flex-column align-items-center">
                <Img fixed={fixed} className="flag-img rounded-circle mb-3" alt={countyReport.node.countryName + ' flag'} />
                <ReactTooltip class="tt" place="bottom">
                  <span>{countyReport.node.countryName}</span>
                </ReactTooltip>
                <p className="text-center d-block text-white">
                  {countyReport.node.countryName}
                </p>
              </div>
            </Link>
            )
            })}
        </div>
      </Container>
    </FlagsWrapper>
  )
}

export default Flags
