import styled from "styled-components"

export const FlagsWrapper = styled.section`
  .grid {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(2, 1fr);
    @media (min-width: 375px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media (min-width: 992px) {
      grid-template-columns: repeat(8, 1fr);
    }
  }
  .flag-container{
    .__react_component_tooltip {
      display: none;
    }
  }
`
