import styled from "styled-components"

const getButtonColor = (props) => {
  const { color } = props
  switch (color) {
    case "green":
      return "#55b9a3"
    case "blue":
      return "#45B8F1"
    case "blue-2":
      return "#3B5CA7"
    default:
      return
  }
}

export const SpeechBubbleWrapper = styled.div`
  position: relative;
  background: ${getButtonColor};
  border-radius: 20px;
  max-width: 100px;
  padding: 8px 20px;
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 0;
    border: 20px solid transparent;
    border-top-color: ${getButtonColor};
    border-bottom: 0;
    margin-left: -20px;
    margin-bottom: -10px;
  }
`
