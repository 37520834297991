import * as React from "react"

function LocationIcon(props) {
  return (
    <svg fill="none" viewBox="0 0 119 123" {...props}>
      <path
        stroke="#1E7FC2"
        strokeWidth={3}
        d="M61.566 2c12.79.551 24.178 6.955 31.664 19.816 3.203 5.503 4.728 11.497 4.77 17.833.022 3.443-.706 6.8-1.73 10.083-2.376 7.61-5.816 14.774-9.565 21.788-7.36 13.766-15.782 26.89-24.725 39.693-.14.202-.28.404-.429.599-1.194 1.57-3.004 1.601-4.144-.009a350.805 350.805 0 01-5.91-8.575c-7.976-12.01-15.6-24.226-22.012-37.143-2.787-5.616-5.307-11.346-7.113-17.36-5.263-17.52 5.25-36.102 20.724-43.108C48.146 3.33 53.887 1.99 61.566 2z"
        clipRule="evenodd"
      />
      <mask
        id="prefix__a"
        width={119}
        height={25}
        x={0}
        y={98}
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M0 98.03h118.986V123H0V98.03z"
          clipRule="evenodd"
        />
      </mask>
      <g mask="url(#prefix__a)">
        <path
          fill="#54B9A4"
          fillRule="evenodd"
          d="M59.407 123c-9.823-.165-19.663-.555-29.474-1.591-5.878-.621-11.73-1.377-17.46-2.858-3.34-.862-6.641-1.896-9.557-3.848-1.944-1.301-3.366-3.379-2.785-5.596.407-1.551 1.505-2.536 2.745-3.375 3.092-2.094 6.6-3.131 10.161-4.017 6.538-1.625 13.197-2.444 19.89-3.028 2.698-.236 5.403-.403 8.1-.648.737-.067 1.126.215 1.519.818 3.547 5.437 7.078 10.885 10.713 16.262 3.361 4.973 9.2 4.934 12.568-.068 3.626-5.384 7.168-10.825 10.724-16.256.343-.523.666-.804 1.335-.758 10.706.74 21.397 1.59 31.752 4.711 2.873.865 5.629 2.028 7.892 4.135 1.857 1.729 1.958 4.382.184 6.342-2.247 2.482-5.268 3.481-8.271 4.459-5.913 1.927-12.039 2.776-18.19 3.486-10.572 1.221-21.184 1.667-31.846 1.83z"
          clipRule="evenodd"
        />
      </g>
      <path
        fill="#1E7FC2"
        fillRule="evenodd"
        d="M81 41c0 11.598-9.626 21-21.5 21S38 52.598 38 41s9.626-21 21.5-21S81 29.402 81 41z"
        clipRule="evenodd"
      />
    </svg>
  )
}

export default LocationIcon
